import React, { useContext, useState } from "react";
import {
    BoldLink,
    BoxContainer,
    FormContainer,
    Input,
    LineText,
    MutedLink,
    SubmitButton,
} from "./common";
import { AccountContext } from './accountContext';
import { Marginer } from "./marginer";
import { ContactContainer, FormGroup, Span, ButtonContainer } from "./styles";
import './style.css'
import { Row, Col, TreeSelect, DatePicker } from "antd";
import { Button } from "./Button";
import EtDatePicker, { EtLocalizationProvider } from "mui-ethiopian-datepicker";
import { treeData } from "./TreeData";
import Dropzone from "./Dropzone";
import { useForm } from "./common/useForm2";
import validate from "./common/validationRules";
import ProgressBar from "@ramonak/react-progress-bar";
import { withTranslation } from "react-i18next";
import { AmhtreeData } from "./AmhTreeData";
import i18n from "../translation";

// function SignupForm() {
const SignupForm = ({t}) => {
  const { values, errors, handleChange, handleSubmit, setProgress, progress, msg, setFormState} = useForm(validate);
  const [date, setDate] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  
  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
  
    setFormState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        ["date"]: selectedDate.toISOString().split("T")[0]
      },
      errors: {
        ...prevState.errors,
        ["date"]: "",
      },
    }));
  };
  
    function ValidationType({ type }) {
      const ErrorMessage = errors[type];
      return ErrorMessage ? <span style={{ marginTop: 0, marginBottom: 10, color: 'red' }}>{ErrorMessage}</span> : <span></span>;
    }
    
    const [value, setValue] = useState();
  
    const onChangeCSC = (newValue) => {
      setValue(newValue);
  
      setFormState((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          ["CSC"]: newValue
        },
        errors: {
          ...prevState.errors,
          ["CSC"]: "",
        },
      }));
  
    };
  
    // const onPopupScroll: TreeSelectProps['onPopupScroll'] = (e) => {
    // };
  
    const onChangeDate  = (date, dateString) => {
      setFormState((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          ["date"]: dateString
        },
        errors: {
          ...prevState.errors,
          ["date"]: "",
        },
      }));
      
    };

//   useEffect(() => {
//     if(msg == "Upload succcessful"){
//       setModalOpen(true)
//     }

//  }, [msg])

    return (
        <BoxContainer>
            <FormGroup autoComplete="off" onSubmit={handleSubmit}>
            <div  className="infohead">{t("Your Information")}</div>
            <Row justify="space-between" align="middle" gutter={[10 , 0]}>
            <Col lg={12} md={12} sm={12} xs={24}>
               <div className="inboxTitle">{t("Your Name (optional)")}</div>
                <Input
                  type="text"
                  name="yourName"
                  placeholder={t("Your Name")}
                  value={values.yourName || ""}
                  onChange={handleChange}
                />
                <ValidationType type="yourName" />
              </Col>
              <Col lg={12} md={12} sm={12} xs={24}>
              <div className="inboxTitle">{t("Your phone")}
                {/* <span style={{color: "red", fontSize: 25}}>*</span> */}
                </div>
              
                <Input
                  type="text"
                  name="yourPhone"
                  placeholder={t("Your Phone Number")}
                  value={values.yourPhone || ""}
                  onChange={handleChange}
                />
                <ValidationType type="yourPhone" />
              </Col>
              <Col span={12}>
              <div className="inboxTitle">{t("Your email (optional)")}</div>
                <Input
                  type="text"
                  name="yourEmail"
                  placeholder={t("Your Email")}
                  value={values.yourEmail || ""}
                  onChange={handleChange}
                />
                <ValidationType type="yourEmail" />
              </Col>
              <Col span={12}>
              <div className="inboxTitle">{t("Your Address (optional)")}</div>
                <Input
                  type="text"
                  name="yourAddress"
                  placeholder={t("Your Address")}
                  value={values.yourAddress || ""}
                  onChange={handleChange}
                />
                <ValidationType type="yourAddress" />
              </Col>
                </Row>
                <hr style={{marginTop: 10}}></hr>
              <div  className="infohead" style={{ marginBottom: 10}}>{t("Incident Information")}</div>
              <Row justify="space-between" align="middle"  gutter={[16,0]}>
              <Col lg={12} md={12} sm={12} xs={24} style={{ marginBottom: i18n.language == 'en' ? 0: 10}}>
              
              {i18n.language == 'en' ? (
                 <>
                 <div className="inboxTitle mb-1">{t("Incident happend date (optional)")}</div>
                 <DatePicker onChange={onChangeDate} disabledDate={d => !d || d.isAfter(new Date()) }   style={{ width: "80%", marginBottom: 10,}}/>
                 </>
                ) : (
                 <EtLocalizationProvider locale="AMH">
                 <EtDatePicker
                 label={t("Incident happend date (optional)")}
                  onChange={handleDateChange}
                  value={date}
                  style={{ height: 30}}
                  minDate={new Date("2010-08-20")}
                  maxDate={new Date()}
                 />
                  </EtLocalizationProvider>
                  )}
                {/* <ValidationType type="date" /> */}
              </Col>
              <Col lg={12} md={12} sm={12} xs={24}>
              <div className="inboxTitle">{t("Incident happend Location")}<span style={{color: "red", fontSize: 20}}>*</span></div>
                <Input
                  type="text"
                  name="location"
                  placeholder={t("Incident happend Location")}
                  value={values.location || ""}
                  onChange={handleChange}
                />
                <ValidationType type="location" />
              </Col>
              <Col lg={12} md={12} sm={12} xs={24}>
                {/* <Input
                  type="text"
                  name="location"
                  placeholder="Incident happend Location"
                  value={values.email || ""}
                  onChange={handleChange}
                /> */}
                <div className="inboxTitle">{t("To which EEU (Region, District, CSC) to inform your suggestion (optional)")}</div>
                 <TreeSelect
                    showSearch
                    className="tree-select-class"
                    value={value}
                    dropdownStyle={{ maxHeight: 1200, overflow: 'auto' }}
                    placeholder={t("Please Select")}
                    onChange={onChangeCSC}
                    treeData={i18n.language == 'en' ? treeData :  AmhtreeData}
                    // onPopupScroll={onPopupScroll}
                    style={{
                        border: '1px solid gray',
                        borderRadius: 4,
                      }}
                  />
                
              </Col>
              <Col lg={12} md={12} sm={12} xs={24}>
              <div className="inboxTitle">{t("Category")}</div>
                                    <select id="category"
                                        name='category'
                                        value={values.category || ""}
                                        onChange={handleChange}
                                        class="category">
                                        <option selected="">{t("Select Category")}</option>
                                        <option value="Bribery">{t("Bribery")}</option>
                                        <option value="Theft">{t("Theft")}</option>
                                        <option value="Billing fraud">{t("Billing fraud")}</option>
                                        <option value="Harassment">{t("Harassment")}</option>
                                    </select>
                
              </Col>
              </Row>
              <Col>
              <div className="inboxTitle">{t("Details of your Report")}
                <span style={{color: "red", fontSize: 25}}>*</span>
                </div>
                <textarea
                rows="5"
                placeholder={t("Details of your Report")}
                id="report"
                name="report"
                style={{
                    width: '100%',
                    border: '1px solid gray',
                    borderRadius: 4,
                    padding: 10
                  }}
                onChange={handleChange}
                ></textarea>
                <ValidationType type="report" />
              </Col>
              <div style={{display: "block", marginTop: 15, marginBottom: 1 }}>{t('Submit supporting evidence for your report')}</div>
              <Dropzone 
                setFormState={setFormState}
              />
              <hr></hr>
              <div  className="infohead">{t("Suspect's Information (optional)")}</div>
              <Row justify="space-between" align="middle" gutter={[10,10]}>
              <Col span={12}>
                <Input
                  type="text"
                  name="suspectName"
                  placeholder={t("Suspect's Name")}
                  value={values.suspectName || ""}
                  onChange={handleChange}
                />
                {/* <ValidationType type="name" /> */}
              </Col>
              <Col span={12}>
                <Input
                  type="text"
                  name="suspectPostion"
                  placeholder={t("Suspect's Position")}
                  value={values.suspectPostion || ""}
                  onChange={handleChange}
                />
                {/* <ValidationType type="email" /> */}
              </Col>
              <Col span={12}>
                <Input
                  type="text"
                  name="suspectPhone"
                  placeholder={t("Suspect's Phone Number")}
                  value={values.suspectPhone || ""}
                  onChange={handleChange}
                />
                <ValidationType type="suspectPhone" />
              </Col>
              </Row>
              <ButtonContainer>
                <Button name="submit">{t("Submit")}</Button>
              </ButtonContainer>
              <div style={{marginTop: 20}}>
              {progress.started &&<ProgressBar completed={progress.pc}></ProgressBar>}
               {(msg && progress.started) && <div>{msg}</div>}
            </div>
            </FormGroup>


            {/* <FormContainer>
        <Input type="text" placeholder="Full name" />
        <Input type="email" placeholder="Email" />
        <Input type="password" placeholder="Password" />
        <Input type="password" placeholder="Confirm password" />
      </FormContainer> */}
            {/* <Marginer direction="vertical" margin={10} />
      <SubmitButton type="submit">Signup</SubmitButton>
      <Marginer direction="vertical" margin="5px" />
      <LineText>
        Already have an account?{" "}
        <BoldLink 
        // onClick={switchToSignin} 
        href="#">
          Signin
        </BoldLink>
      </LineText> */}
        </BoxContainer>
    );
}

export default withTranslation()(SignupForm);