import React, { useState } from "react";

import { styles } from './styles'

import './avatar.css'
import chat from '../assets/chat.gif'; 

const Avatar = props => {
    const [hovered, setHovered] = useState(false)

    return (
        <div style={props.style}>
            {hovered && <div 
                className='transition-3'
                style={{
                    ...styles.avatarHello,
                    ...{ opacity: hovered ? '1' : '0' },
                }}
            >
                Hey it's EEU 🤙
            </div>}

            <div 
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={() => props.onClick && props.onClick()}
                className='chatWithMeButton transition-3'
                style={{
                    ...{ border: hovered ? '2px solid #7a39e0' : '1px solid  #f9f0ff', backgroundImage: `url(${chat})` }
                }}
            />
        </div>
    )
}

export default Avatar;