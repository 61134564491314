export const AmhtreeData =  [
    {
      value: 'አዲስ አበባ',
      title: 'አዲስ አበባ',
      children: [
        {
          value: 'ሰሜን አዲስ አበባ ዲስትሪክት',
          title: 'ሰሜን አዲስ አበባ ዲስትሪክት',
          children: [
            {
              value: 'ሰሜን አ.አ አ.መ.ማ ቁ.1',
              title: 'ሰሜን አ.አ አ.መ.ማ ቁ.1',
            },
            {
              value: 'ሰሜን አ.አ አ.መ.ማ ቁ.2',
              title: 'ሰሜን አ.አ አ.መ.ማ ቁ.2',
            },
            {
              value: 'ሰሜን አ.አ አ.መ.ማ ቁ.3',
              title: 'ሰሜን አ.አ አ.መ.ማ ቁ.3',
            },
            {
              value: 'ሰሜን አ.አ አ.መ.ማ ቁ.4',
              title: 'ሰሜን አ.አ አ.መ.ማ ቁ.4',
            },
            {
              value: 'ሰሜን አ.አ አ.መ.ማ ቁ.5',
              title: 'ሰሜን አ.አ አ.መ.ማ ቁ.5',
            },
          ],
        },
        {
          value: 'ምሥራቅ አዲስ አበባ ዲስትሪክት',
          title: 'ምሥራቅ አዲስ አበባ ዲስትሪክት',
          children: [
            {
              value: 'ምሥራቅ አ.አ አ.መ.ማ ቁ.1',
              title: 'ምሥራቅ አ.አ አ.መ.ማ ቁ.1',
            },
            {
              value: 'ምሥራቅ አ.አ አ.መ.ማ ቁ.2',
              title: 'ምሥራቅ አ.አ አ.መ.ማ ቁ.2',
            },
            {
              value: 'ምሥራቅ አ.አ አ.መ.ማ ቁ.3',
              title: 'ምሥራቅ አ.አ አ.መ.ማ ቁ.3',
            },
            {
              value: 'ምሥራቅ አ.አ አ.መ.ማ ቁ.4',
              title: 'ምሥራቅ አ.አ አ.መ.ማ ቁ.4',
            },
            {
              value: 'ምሥራቅ አ.አ አ.መ.ማ ቁ.5',
              title: 'ምሥራቅ አ.አ አ.መ.ማ ቁ.5',
            },
            {
              value: 'ምሥራቅ አ.አ አ.መ.ማ ቁ.6',
              title: 'ምሥራቅ አ.አ አ.መ.ማ ቁ.6',
            },
            {
              value: 'ምሥራቅ አ.አ አ.መ.ማ ቁ.7',
              title: 'ምሥራቅ አ.አ አ.መ.ማ ቁ.7',
            },
            {
              value: 'ምሥራቅ አ.አ አ.መ.ማ ቁ.8',
              title: 'ምሥራቅ አ.አ አ.መ.ማ ቁ.8',
            },
            {
              value: 'ምሥራቅ አ.አ አ.መ.ማ ቁ.9',
              title: 'ምሥራቅ አ.አ አ.መ.ማ ቁ.9',
            },
          ],
        },
        {
          value: 'ምዕራብ አዲስ አበባ ዲስትሪክት',
          title: 'ምዕራብ አዲስ አበባ ዲስትሪክት',
          children: [
            {
              value: 'ምዕራብ አ.አ አ.መ.ማ ቁ.1',
              title: 'ምዕራብ አ.አ አ.መ.ማ ቁ.1',
            },
            {
              value: 'ምዕራብ አ.አ አ.መ.ማ ቁ.2',
              title: 'ምዕራብ አ.አ አ.መ.ማ ቁ.2',
            },
            {
              value: 'ምዕራብ አ.አ አ.መ.ማ ቁ.3',
              title: 'ምዕራብ አ.አ አ.መ.ማ ቁ.3',
            },
            {
              value: 'ምዕራብ አ.አ አ.መ.ማ ቁ.4',
              title: 'ምዕራብ አ.አ አ.መ.ማ ቁ.4',
            },
            {
              value: 'ምዕራብ አ.አ አ.መ.ማ ቁ.5',
              title: 'ምዕራብ አ.አ አ.መ.ማ ቁ.5',
            },
            {
              value: 'ምዕራብ አ.አ አ.መ.ማ ቁ.6',
              title: 'ምዕራብ አ.አ አ.መ.ማ ቁ.6',  
            },
            {
              value: 'ምዕራብ አ.አ አ.መ.ማ ቁ.7',
              title: 'ምዕራብ አ.አ አ.መ.ማ ቁ.7',
            },
            {
              value: 'ምዕራብ አ.አ አ.መ.ማ ቁ.8',
              title: 'ምዕራብ አ.አ አ.መ.ማ ቁ.8',
            },
          ],
        },
        {
          value: 'ደቡብ አዲስ አበባ ዲስትሪክት',
          title: 'ደቡብ አዲስ አበባ ዲስትሪክት',
          children: [
            {
              value: 'ደቡብ አ.አ አ.መ.ማ ቁ.1',
              title: 'ደቡብ አ.አ አ.መ.ማ ቁ.1',
            },
            {
              value: 'ደቡብ አ.አ አ.መ.ማ ቁ.2',
              title: 'ደቡብ አ.አ አ.መ.ማ ቁ.2',
            },
            {
              value: 'ደቡብ አ.አ አ.መ.ማ ቁ.3',
              title: 'ደቡብ አ.አ አ.መ.ማ ቁ.3',
            },
            {
              value: 'ደቡብ አ.አ አ.መ.ማ ቁ.4',
              title: 'ደቡብ አ.አ አ.መ.ማ ቁ.4',
            },
            {
              value: 'ደቡብ አ.አ አ.መ.ማ ቁ.5',
              title: 'ደቡብ አ.አ አ.መ.ማ ቁ.5',
            },
            {
              value: 'ደቡብ አ.አ አ.መ.ማ ቁ.6',
              title: 'ደቡብ አ.አ አ.መ.ማ ቁ.6',
            },
            {
              value: 'ደቡብ አ.አ አ.መ.ማ ቁ.7',
              title: 'ደቡብ አ.አ አ.መ.ማ ቁ.7',
            },
            {
              value: 'ደቡብ አ.አ አ.መ.ማ ቁ.8',
              title: 'ደቡብ አ.አ አ.መ.ማ ቁ.8',
            },
            {
              value: 'ደቡብ አ.አ አ.መ.ማ ቁ.9',
              title: 'ደቡብ አ.አ አ.መ.ማ ቁ.9',
            },
            {
              value: 'ደቡብ አ.አ አ.መ.ማ ቁ.10',
              title: 'ደቡብ አ.አ አ.መ.ማ ቁ.10',
            },
          ],
        },
      ]},//Addis Ababa Ends Here. Add Comma to Continue
        {
          value: 'ኦሮሚያ ክልል',
          title: 'ኦሮሚያ ክልል',
          children: [
          {
          value: 'አዳማ ዞን',
          title: 'አዳማ ዞን',
          children: [
            {
              value: 'አዳማ ቁ. 1',
              title: 'አዳማ ቁ. 1',
            },
            {
              value: 'አዳማ ቁ. 2',
              title: 'አዳማ ቁ. 2',
            },
            {
              value: 'አዳማ ቁ. 3',
              title: 'አዳማ ቁ. 3',
            },
            {
              value: 'አዳማ ቁ. 4',
              title: 'አዳማ ቁ. 4',
            },
            {
              value: 'አቦምሳ',
              title: 'አቦምሳ',
            },
            {
              value: 'አዳማቱሉ',
              title: 'አዳማቱሉ',
            },
            {
              value: 'በቆጂ',
              title: 'በቆጂ',
            },
            {
              value: 'ቡልቡላ',
              title: 'ቡልቡላ',
            },
            {
              value: 'ደራ',
              title: 'ደራ',
            },
            {
              value: 'ከርሳ',
              title: 'ከርሳ',
            },
            {
              value: 'አዋሽ መልካሳ',
              title: 'አዋሽ መልካሳ',
            },
            {
              value: 'ሳጉሬ',
              title: 'ሳጉሬ',
            },
            {
              value: 'ዌላንቺቲ',
              title: 'ዌላንቺቲ',
            },
            {
              value: 'ወንጂ',
              title: 'ወንጂ',
            },
            {
              value: 'አሰላ',
              title: 'አሰላ',
            },
            {
              value: 'መተሃራ',
              title: 'መተሃራ',
            },
            {
              value: 'ሞጆ',
              title: 'ሞጆ',
            },
            {
              value: 'ዘዋይ',
              title: 'ዘዋይ',
            },
            {
              value: 'ጎበሳ',
              title: 'ጎበሳ',
            },
            {
              value: 'አርሲ ሮቤ',
              title: 'አርሲ ሮቤ',
            },
            {
              value: 'አለም ቴና',
              title: 'አለም ቴና',
            },
            {
              value: 'ኤትዮ',
              title: 'ኤትዮ',
            },
            {
              value: 'ጮሌ',
              title: 'ጮሌ',
            },
            {
              value: 'ኦጎልቾ',
              title: 'ኦጎልቾ',
            },
            {
              value: 'ደኬሲስ',
              title: 'ደኬሲስ',
            },
            {
              value: 'ሁሩታ',
              title: 'ሁሩታ',
            },
            {
              value: 'መቂ',
              title: 'መቂ',
            },
            {
              value: 'አሰላ ቁ. 2',
              title: 'አሰላ ቁ. 2',
            },
            {
              value: 'በሌ',
              title: 'በሌ',
            },
            {
              value: 'አርቦዬ',
              title: 'አርቦዬ',
            }
          ]
        },
        {
          value: 'አምቦ ዞን',
          title: 'አምቦ ዞን',
          children: [
            {
              value: 'አምቦ',
              title: 'አምቦ',
            },
            {
              value: 'ቱሉቦሎ',
              title: 'ቱሉቦሎ',
            },
            {
              value: 'ወሊሶ',
              title: 'ወሊሶ',
            },
            {
              value: 'ባንዩ',
              title: 'ባንዩ',
            },
            {
              value: 'አማያ',
              title: 'አማያ',
            },
            {
              value: 'አዲስ አለም',
              title: 'አዲስ አለም',
            },
            {
              value: 'ጊንቺ',
              title: 'ጊንቺ',
            },
            {
              value: 'ካችሲ',
              title: 'ካችሲ',
            },
            {
              value: 'ጉደር',
              title: 'ጉደር',
            },
            {
              value: 'ሆርማት',
              title: 'ሆርማት',
            },
            {
              value: 'ገዶ',
              title: 'ገዶ',
            },
            {
              value: 'እጃጂ',
              title: 'እጃጂ',
            },
            {
              value: 'ባኮ',
              title: 'ባኮ',
            },
            {
              value: 'ዋይ',
              title: 'ዋይ',
            },
            {
              value: 'ጀልዱ',
              title: 'ጀልዱ',
            },
            {
              value: 'ሴዎ',
              title: 'ሴዎ',
            },
            {
              value: 'ሰደንሶዶ',
              title: 'ሰደንሶዶ',
            },
            {
              value: 'አቡና',
              title: 'አቡና',
            },
            {
              value: 'ሸነን',
              title: 'ሸነን',
            },
            {
              value: 'ቢሎ',
              title: 'ቢሎ',
            },
            {
              value: 'ቡሳ',
              title: 'ቡሳ',
            },
            {
              value: 'ሲልካምባ',
              title: 'ሲልካምባ',
            }
          ]
        },
        {
          value: 'ባሌ ሮብ ዞን',
          title: 'ባሌ ሮብ ዞን',
          children: [
            {
              value: 'ሮብ',
              title: 'ሮብ',
            },
            {
              value: 'ባሌ ጎባ',
              title: 'ባሌ ጎባ',
            },
            {
              value: 'አዳባ',
              title: 'አዳባ',
            },
            {
              value: 'አጋርፋ',
              title: 'አጋርፋ',
            },
            {
              value: 'ጋሴራ',
              title: 'ጋሴራ',
            },
            {
              value: 'ጎሮ',
              title: 'ጎሮ',
            },
            {
              value: 'ደሎመና',
              title: 'ደሎመና',
            },
            {
              value: 'ሐረ ዱሜሌ',
              title: 'ሐረ ዱሜሌ',
            },
            {
              value: 'ራይቱ/ቸረቸር',
              title: 'ራይቱ/ቸረቸር',
            },
            {
              value: 'ዲንሾ',
              title: 'ዲንሾ',
            },
            {
              value: 'ወርካ',
              title: 'ወርካ',
            },
            {
              value: 'ጃራ',
              title: 'ጃራ',
            }
  
          ]
        },
        {
          value: "ጨሮ ዞን",
          title: "ጨሮ ዞን",
          children: [
            {
              value: 'ጨሮ',
              title: 'ጨሮ',
            },
            {
              value: 'ባቢላ',
              title: 'ባቢላ',
            },
            {
              value: 'በዳሳ',
              title: 'በዳሳ',
            },
            {
              value: 'ቢስዱሞ',
              title: 'ቢስዱሞ',
            },
            {
              value: 'ኮምቦልቻ',
              title: 'ኮምቦልቻ',
            },
            {
              value: 'ገለምሳ',
              title: 'ገለምሳ',
            },
            {
              value: 'ጉርሱም',
              title: 'ጉርሱም',
            },
            {
              value: 'ህርና',
              title: 'ህርና',
            },
            {
              value: 'ካራሚሌ',
              title: 'ካራሚሌ',
            },
            {
              value: 'ከርሳ',
              title: 'ከርሳ',
            },
            {
              value: 'ደደር',
              title: 'ደደር',
            },
            {
              value: 'ሐሮማያ',
              title: 'ሐሮማያ',
            },
            {
              value: 'ገራዋ',
              title: 'ገራዋ',
            },
            {
              value: 'መጫራ',
              title: 'መጫራ',
            },
            {
              value: 'መኣሶ',
              title: 'መኣሶ',
            },
            {
              value: 'ቸናገሳን',
              title: 'ቸናገሳን',
            },
            {
              value: 'ፌዲስ',
              title: 'ፌዲስ',
            },
            {
              value: 'አወዳይ',
              title: 'አወዳይ',
            }
  
          ]
        },
        {
          value: 'ፊንፍነ ዞርያ ዞን',
          title: 'ፊንፍነ ዞርያ ዞን',
          children: [
            {
              value: 'ሰንዳፋ',
              title: 'ሰንዳፋ',
            },
            {
              value: 'ሽኖ',
              title: 'ሽኖ',
            },
            {
              value: 'ሙኬቱሪ',
              title: 'ሙኬቱሪ',
            },
            {
              value: 'ፊቼ',
              title: 'ፊቼ',
            },
            {
              value: 'ጌብሬጉራቻ',
              title: 'ጌብሬጉራቻ',
            },
            {
              value: 'ኤጀሬ',
              title: 'ኤጀሬ',
            },
            {
              value: 'ጉንዶ መስከል',
              title: 'ጉንዶ መስከል',
            },
            {
              value: 'ለገታፎ',
              title: 'ለገታፎ',
            },
            {
              value: 'ጎሀጽዮን',
              title: 'ጎሀጽዮን',
            },
            {
              value: 'ቡራዩ',
              title: 'ቡራዩ',
            },
            {
              value: 'ተረ',
              title: 'ተረ',
            },
            {
              value: 'ቻንኮ',
              title: 'ቻንኮ',
            },
            {
              value: 'ሆሊታ',
              title: 'ሆሊታ',
            },
            {
              value: 'ሙገር',
              title: 'ሙገር',
            },
            {
              value: 'ሱልልታ',
              title: 'ሱልልታ',
            },
            {
              value: 'ታቴከ',
              title: 'ታቴከ',
            },
            {
              value: 'ዱከም',
              title: 'ዱከም',
            },
            {
              value: 'ቢሾፍቱ ቁ. 1',
              title: 'ቢሾፍቱ ቁ. 1',
            },
            {
              value: 'ቢሾፍቱ ቁ. 2',
              title: 'ቢሾፍቱ ቁ. 2',
            },
            {
              value: 'ቼፌ ዶንሳ',
              title: 'ቼፌ ዶንሳ',
            },
            {
              value: 'አዱላላ',
              title: 'አዱላላ',
            },
            {
              value: 'ገላን',
              title: 'ገላን',
            },
            {
              value: 'ሰበታ',
              title: 'ሰበታ',
            },
            {
              value: 'አሌምጌና',
              title: 'አሌምጌና',
            },
            {
              value: 'ፉሪ',
              title: 'ፉሪ',
            },
            {
              value: 'አንፎ መዳ',
              title: 'አንፎ መዳ',
            },
            {
              value: 'ሌመን',
              title: 'ሌመን',
            },
            {
              value: 'ኦሮሚያ ኮንዶሚኒየም',
              title: 'ኦሮሚያ ኮንዶሚኒየም',
            },
            {
              value: 'ሺኖ',
              title: 'ሺኖ',
            }
  
          ]
        },
        {
          value: 'ጂማ ዞን',
          title: 'ጂማ ዞን',
          children: [
            {
              value: 'ጂማ ቁ. 1',
              title: 'ጂማ ቁ. 1',
            },
            {
              value: 'ጂማ ቁ. 2',
              title: 'ጂማ ቁ. 2',
            },
            {
              value: 'አሰንዲቦ',
              title: 'አሰንዲቦ',
            },
            {
              value: 'ሊሞ',
              title: 'ሊሞ',
            },
            {
              value: 'ቶባ',
              title: 'ቶባ',
            },
            {
              value: 'ሰኮሮ',
              title: 'ሰኮሮ',
            },
            {
              value: 'አጋሮ',
              title: 'አጋሮ',
            },
            {
              value: 'ዱምቱ',
              title: 'ዱምቱ',
            },
            {
              value: 'ጋቲራ',
              title: 'ጋቲራ',
            },
            {
              value: 'ገራ',
              title: 'ገራ',
            },
            {
              value: 'ደዶ',
              title: 'ደዶ',
            },
            {
              value: 'ሸቤ',
              title: 'ሸቤ',
            },
            {
              value: 'አጋሮ ቁ. 2',
              title: 'አጋሮ ቁ. 2',
            },
            {
              value: 'ደምቢ',
              title: 'ደምቢ',
            },
            {
              value: 'ቼራ',
              title: 'ቼራ',
            },
            {
              value: 'ቤዴላ',
              title: 'ቤዴላ',
            },
            {
              value: 'ቼዋካ',
              title: 'ቼዋካ',
            }
  
          ]
        },
        {
          value: 'መቱ ወረዳ',
          title: 'መቱ ወረዳ',
          children: [
            {
              value: 'ሜቱ',
              title: 'ሜቱ',
            },
            {
              value: 'ጎሬ',
              title: 'ጎሬ',
            },
            {
              value: 'ሱፒ',
              title: 'ሱፒ',
            },
            {
              value: 'ያዩ',
              title: 'ያዩ',
            },
            {
              value: 'ቡሬ',
              title: 'ቡሬ',
            },
            {
              value: 'ዱረኒ',
              title: 'ዱረኒ',
            },
            {
              value: 'ዲረሙ',
              title: 'ዲረሙ',
            },
            {
              value: 'ደጋ',
              title: 'ደጋ',
            },
            {
              value: 'ሁርሙ',
              title: 'ሁርሙ',
            },
            {
              value: 'ኖፓ',
              title: 'ኖፓ',
            },
            {
              value: 'በቾ',
              title: 'በቾ',
            },
            {
              value: 'ሴሌ ኖኖ',
              title: 'ሴሌ ኖኖ',
            },
            {
              value: 'ላሎ',
              title: 'ላሎ',
            }
          ]
        },
        {
          value: 'ነቀምት ወረዳ',
          title: 'ነቀምት ወረዳ',
          children: [
            {
              value: 'ነቀምት',
              title: 'ነቀምት',
            },
            {
              value: 'ፊንጫ',
              title: 'ፊንጫ',
            },
            {
              value: 'ሻምቡ',
              title: 'ሻምቡ',
            },
            {
              value: 'አሊቦ',
              title: 'አሊቦ',
            },
            {
              value: 'መጫራ',
              title: 'መጫራ',
            },
            {
              value: 'ሙጊ',
              title: 'ሙጊ',
            },
            {
              value: 'ሮብጌቤያ',
              title: 'ሮብጌቤያ',
            },
            {
              value: 'ደምቢዶሎ',
              title: 'ደምቢዶሎ',
            },
            {
              value: 'ነጊ',
              title: 'ነጊ',
            },
            {
              value: 'ጉሊሶ',
              title: 'ጉሊሶ',
            },
            {
              value: 'መንዲ',
              title: 'መንዲ',
            },
            {
              value: 'ነድጆ',
              title: 'ነድጆ',
            },
            {
              value: 'ግሚ',
              title: 'ግሚ',
            },
            {
              value: 'ሀሮሴቡ',
              title: 'ሀሮሴቡ',
            },
            {
              value: 'ኖለካባ',
              title: 'ኖለካባ',
            },
            {
              value: 'ጊዳ አያና',
              title: 'ጊዳ አያና',
            },
            {
              value: 'ሲሬ',
              title: 'ሲሬ',
            },
            {
              value: 'አንገር ጉቲን',
              title: 'አንገር ጉቲን',
            },
            {
              value: 'ክራሙ',
              title: 'ክራሙ',
            },
            {
              value: 'አርጆ',
              title: 'አርጆ',
            },
            {
              value: 'ዴዱ',
              title: 'ዴዱ',
            },
            {
              value: 'ሳሲጋ',
              title: 'ሳሲጋ',
            },
            {
              value: 'ኡኬ',
              title: 'ኡኬ',
            },
            {
              value: 'አርጆ ጉዱቱ',
              title: 'አርጆ ጉዱቱ',
            },
            {
              value: 'ኑኑ ኩምባ',
              title: 'ኑኑ ኩምባ',
            },
            {
              value: 'ኦበራ',
              title: 'ኦበራ',
            },
            {
              value: 'ነቀምት ቁጥር ሁለት',
              title: 'ነቀምት ቁጥር ሁለት',
            },
            {
              value: 'ገንጂ',
              title: 'ገንጂ',
            },
            {
              value: 'ሀሮ',
              title: 'ሀሮ',
            },
            {
              value: 'ካኬ',
              title: 'ካኬ',
            }
          ]
        },
        {
          value: 'ሻሸመኔ ወረዳ',
          title: 'ሻሸመኔ ወረዳ',
          children: [
            {
              value: 'ሻሸመኔ ቁጥር 1',
              title: 'ሻሸመኔ ቁጥር 1',
            },
            {
              value: 'ሻሸመኔ ቁጥር 2',
              title: 'ሻሸመኔ ቁጥር 2',
            },
            {
              value: 'ቦሬ',
              title: 'ቦሬ',
            },
            {
              value: 'ኃ/ማርያም',
              title: 'ኃ/ማርያም',
            },
            {
              value: 'ኮፌሌ',
              title: 'ኮፌሌ',
            },
            {
              value: 'ን/ቦረኔዓ',
              title: 'ን/ቦረኔዓ',
            },
            {
              value: 'ሻኪሶ',
              title: 'ሻኪሶ',
            },
            {
              value: 'ያቤሎ',
              title: 'ያቤሎ',
            },
            {
              value: 'አርሲ ነገሌ',
              title: 'አርሲ ነገሌ',
            },
            {
              value: 'ክበር መንግስቱ',
              title: 'ክበር መንግስቱ',
            },
            {
              value: 'ሞያሌ',
              title: 'ሞያሌ',
            },
            {
              value: 'ሶሎሞ',
              title: 'ሶሎሞ',
            },
            {
              value: 'ቶሬ',
              title: 'ቶሬ',
            },
            {
              value: 'ኮኮሳ',
              title: 'ኮኮሳ',
            },
            {
              value: 'ቢሻን ጉራጫ',
              title: 'ቢሻን ጉራጫ',
            },
            {
              value: 'ጋንጎ',
              title: 'ጋንጎ',
            },
            {
              value: 'ቀርጫ',
              title: 'ቀርጫ',
            },
            {
              value: 'አዲ',
              title: 'አዲ',
            },
            {
              value: 'መጋ',
              title: 'መጋ',
            },
            {
              value: 'ጎልጆታ',
              title: 'ጎልጆታ',
            },
            {
              value: 'ሄረቄሎ',
              title: 'ሄረቄሎ',
            },
            {
              value: 'ቢዲሬ',
              title: 'ቢዲሬ',
            },
            {
              value: 'ዲምቱ6',
              title: 'ዲምቱ6',
            },
            {
              value: 'መልካ ሶዳ',
              title: 'መልካ ሶዳ',
            },
            {
              value: 'አሳሣ',
              title: 'አሳሣ',
            }
          ]
        }
  
  
      ]
    },// this is where Oromia Ends, put Comma here
    {
      value: 'አማራ ክልል',
      title: 'አማራ ክልል',
      children: [
        {
          value: 'ባህር ዳር ወረዳ',
          title: 'ባህር ዳር ወረዳ',
          children: [
            {
              value: 'ባህር ዳር 1',
              title: 'ባህር ዳር 1',
            },
            {
              value: 'ባህር ዳር 2',
              title: 'ባህር ዳር 2',
            },
            {
              value: 'አደት',
              title: 'አደት',
            },
            {
              value: 'ጫኝ',
              title: 'ጫኝ',
            },
            {
              value: 'ዱርበትየ',
              title: 'ዱርበትየ',
            },
            {
              value: 'ኮሶበር',
              title: 'ኮሶበር',
            },
            {
              value: 'መራዊ',
              title: 'መራዊ',
            },
            {
              value: 'ወረታ',
              title: 'ወረታ',
            },
            {
              value: 'ዳንግሊያ',
              title: 'ዳንግሊያ',
            },
            {
              value: 'ደብረ ታቦር',
              title: 'ደብረ ታቦር',
            },
            {
              value: 'እብነት',
              title: 'እብነት',
            },
            {
              value: 'ጃዊ',
              title: 'ጃዊ',
            },
            {
              value: 'እስቲ',
              title: 'እስቲ',
            },
            {
              value: 'አዲስ ዘመን',
              title: 'አዲስ ዘመን',
            },
            {
              value: 'ወገዳ',
              title: 'ወገዳ',
            },
            {
              value: 'ግሺባይ',
              title: 'ግሺባይ',
            },
            {
              value: 'አዲስ ከደም',
              title: 'አዲስ ከደም',
            },
            {
              value: 'ሊቤን ሲ.ኤስ.ሲ',
              title: 'ሊቤን ሲ.ኤስ.ሲ',
            },
            {
              value: 'ክላጅ',
              title: 'ክላጅ',
            },
            {
              value: 'አምቤ ሳሜ',
              title: 'አምቤ ሳሜ',
            },
            {
              value: 'ንፋስ መዋቻ',
              title: 'ንፋስ መዋቻ',
            },
            {
              value: 'ደብረታቦር 2',
              title: 'ደብረታቦር 2',
            },
            {
              value: 'ኪሚርዲንጌ',
              title: 'ኪሚርዲንጌ',
            },
            {
              value: 'ጎንጂ',
              title: 'ጎንጂ',
            },
            {
              value: 'ባህር ዳር 3 ሲ.ኤስ.ሲ',
              title: 'ባህር ዳር 3 ሲ.ኤስ.ሲ',
            },
            {
              value: 'ታች ገንት(አርብ ገቤያ)',
              title: 'ታች ገንት(አርብ ገቤያ)',
            },
            {
              value: 'አንዳቤት',
              title: 'አንዳቤት',
            },
            {
              value: 'ገመጃቤት',
              title: 'ገመጃቤት',
            }
          ]
        },
        {
          value: 'ደሴ ወረዳ',
          title: 'ደሴ ወረዳ',
          children: [
            {
              value: 'ደሴ 1',
              title: 'ደሴ 1'
            },
            {
              value: 'ደሴ 2',
              title: 'ደሴ 2'
            },
            {
              value: 'ባቲ',
              title: 'ባቲ'
            },
            {
              value: 'አክስታ',
              title: 'አክስታ'
            },
            {
              value: 'ሃልከ',
              title: 'ሃልከ'
            },
            {
              value: 'መካነ ሰላም',
              title: 'መካነ ሰላም'
            },
            {
              value: 'ተንታ',
              title: 'ተንታ'
            },
            {
              value: 'ወርሉ',
              title: 'ወርሉ'
            },
            {
              value: 'ከሚሴ',
              title: 'ከሚሴ'
            },
            {
              value: 'ኮምቦልቻ ቁጥር 1',
              title: 'ኮምቦልቻ ቁጥር 1'
            },
            {
              value: 'ኮምቦልቻ ቁጥር 2',
              title: 'ኮምቦልቻ ቁጥር 2'
            },
            {
              value: 'ቅዱስ አጅባር',
              title: 'ቅዱስ አጅባር'
            },
            {
              value: 'ኩታበር',
              title: 'ኩታበር'
            },
            {
              value: 'ወግዲ',
              title: 'ወግዲ'
            },
            {
              value: 'ውችሌ',
              title: 'ውችሌ'
            },
            {
              value: 'ከላላ',
              title: 'ከላላ'
            },
            {
              value: 'ወግል ተና',
              title: 'ወግል ተና'
            },
            {
              value: 'አልብኮ',
              title: 'አልብኮ'
            },
            {
              value: 'ቢስቲማ',
              title: 'ቢስቲማ'
            },
            {
              value: 'ጫፈ ሮቢት',
              title: 'ጫፈ ሮቢት'
            },
            {
              value: 'ሃርቡ',
              title: 'ሃርቡ'
            },
            {
              value: 'ጃማ',
              title: 'ጃማ'
            },
            {
              value: 'መሻ',
              title: 'መሻ'
            },
            {
              value: 'ቅዱስ',
              title: 'ቅዱስ'
            },
            {
              value: 'መኮይ',
              title: 'መኮይ'
            },
            {
              value: 'ደሴ ወስ ቁ 3',
              title: 'ደሴ ወስ ቁ 3'
            },
            {
              value: 'ወይን አምባ',
              title: 'ወይን አምባ'
            }
          ]
        },
        {
          value: 'ጎንዳር ወረዳ',
          title: 'ጎንዳር ወረዳ',
          children: [
            {
              value: 'ጎንዳር 1',
              title: 'ጎንዳር 1'
            },
            {
              value: 'ጎንዳር 2',
              title: 'ጎንዳር 2'
            },
            {
              value: 'ጎንዳር  ቁጥር 3',
              title: 'ጎንዳር  ቁጥር 3'
            },
            {
              value: 'ዳባት',
              title: 'ዳባት'
            },
            {
              value: 'ደባርክ',
              title: 'ደባርክ'
            },
            {
              value: 'ኮላዲባ',
              title: 'ኮላዲባ'
            },
            {
              value: 'መተማ',
              title: 'መተማ'
            },
            {
              value: 'ሳንጃ',
              title: 'ሳንጃ'
            },
            {
              value: 'መርከስነት',
              title: 'መርከስነት'
            },
            {
              value: 'ደሊ',
              title: 'ደሊ'
            },
            {
              value: 'አረባያ',
              title: 'አረባያ'
            },
            {
              value: 'ገላጆ',
              title: 'ገላጆ'
            },
            {
              value: 'ጉሃሳ',
              title: 'ጉሃሳ'
            },
            {
              value: 'መካነ ብርሃን',
              title: 'መካነ ብርሃን'
            },
            {
              value: 'ሻዌራ',
              title: 'ሻዌራ'
            },
            {
              value: 'ተከልዲንግይ',
              title: 'ተከልዲንግይ'
            },
            {
              value: 'አምባ ጊዮርጊስ',
              title: 'አምባ ጊዮርጊስ'
            },
            {
              value: 'መተማ ዮሃንስ',
              title: 'መተማ ዮሃንስ'
            },
            {
              value: 'ቸልጋ',
              title: 'ቸልጋ'
            },
            {
              value: 'አዳሪካየ',
              title: 'አዳሪካየ'
            },
            {
              value: 'አብዲራፊ',
              title: 'አብዲራፊ'
            },
            {
              value: 'ቹሓይት',
              title: 'ቹሓይት'
            },
            {
              value: 'ኪራክር',
              title: 'ኪራክር'
            }
          ]
        },
        {
          "value": "ደብረ ብርሃን ወረዳ",
          "title": "ደብረ ብርሃን ወረዳ",
          "children": [
            {
              "value": "ደብረ ብርሃን ቁጥር 1",
              "title": "ደብረ ብርሃን ቁጥር 1"
            },
            {
              "value": "ደብረ ብርሃን ቁጥር 2",
              "title": "ደብረ ብርሃን ቁጥር 2"
            },
            {
              "value": "እነዋሪ",
              "title": "እነዋሪ"
            },
            {
              "value": "አንኮበር",
              "title": "አንኮበር"
            },
            {
              "value": "አሌም ኬተማ",
              "title": "አሌም ኬተማ"
            },
            {
              "value": "ለሚ",
              "title": "ለሚ"
            },
            {
              "value": "ሃገረ ማርያም",
              "title": "ሃገረ ማርያም"
            },
            {
              "value": "አርርቲ",
              "title": "አርርቲ"
            },
            {
              "value": "ደብረ ሲና",
              "title": "ደብረ ሲና"
            },
            {
              "value": "መሃል መዳ",
              "title": "መሃል መዳ"
            },
            {
              "value": "ሞላሌ",
              "title": "ሞላሌ"
            },
            {
              "value": "ሸዋ ሮቢት",
              "title": "ሸዋ ሮቢት"
            },
            {
              "value": "ግሺራብል",
              "title": "ግሺራብል"
            },
            {
              "value": "መቲ ሂብላ",
              "title": "መቲ ሂብላ"
            },
            {
              "value": "ሰላዲንጋይ",
              "title": "ሰላዲንጋይ"
            },
            {
              "value": "ግና ገር",
              "title": "ግና ገር"
            },
            {
              "value": "ደንባ",
              "title": "ደንባ"
            },
            {
              "value": "ቻቻ",
              "title": "ቻቻ"
            },
            {
              "value": "አታዬ",
              "title": "አታዬ"
            },
            {
              "value": "ሚዳ",
              "title": "ሚዳ"
            }
          ]
        },
        {
          "value": "ደብረ ማርቆስ ወረዳ",
          "title": "ደብረ ማርቆስ ወረዳ",
          "children": [
            {
              "value": "ደብረ ማርቆስ ቁጥር 1",
              "title": "ደብረ ማርቆስ ቁጥር 1"
            },
            {
              "value": "ደብረ ማርቆስ ቁጥር 2",
              "title": "ደብረ ማርቆስ ቁጥር 2"
            },
            {
              "value": "አማኑኤል",
              "title": "አማኑኤል"
            },
            {
              "value": "ቢችና",
              "title": "ቢችና"
            },
            {
              "value": "ደጀን",
              "title": "ደጀን"
            },
            {
              "value": "ግንደ ወይን",
              "title": "ግንደ ወይን"
            },
            {
              "value": "ኩዬ",
              "title": "ኩዬ"
            },
            {
              "value": "ሞታ",
              "title": "ሞታ"
            },
            {
              "value": "ዲጎትሰዮን",
              "title": "ዲጎትሰዮን"
            },
            {
              "value": "ሉማሜ",
              "title": "ሉማሜ"
            },
            {
              "value": "መርቶ ማርያም",
              "title": "መርቶ ማርያም"
            },
            {
              "value": "የጁበ",
              "title": "የጁበ"
            },
            {
              "value": "ቡሪ",
              "title": "ቡሪ"
            },
            {
              "value": "ፍ/ሰላም",
              "title": "ፍ/ሰላም"
            },
            {
              "value": "ሸንዲ",
              "title": "ሸንዲ"
            },
            {
              "value": "ደንበቻ",
              "title": "ደንበቻ"
            },
            {
              "value": "ፈረስ ቤት",
              "title": "ፈረስ ቤት"
            },
            {
              "value": "ደብረ እልያስ",
              "title": "ደብረ እልያስ"
            },
            {
              "value": "ገበዝ ማርያም",
              "title": "ገበዝ ማርያም"
            },
            {
              "value": "የዱሃ",
              "title": "የዱሃ"
            }
          ]
        },
        {
          "value": "ዎሊዲያ ወረዳ",
          "title": "ዎሊዲያ ወረዳ",
          "children": [
            {
              "value": "ዎሌዲያ",
              "title": "ዎሌዲያ"
            },
            {
              "value": "ጋሸና",
              "title": "ጋሸና"
            },
            {
              "value": "መርሳ",
              "title": "መርሳ"
            },
            {
              "value": "ዳዌንት",
              "title": "ዳዌንት"
            },
            {
              "value": "ፊላቅት",
              "title": "ፊላቅት"
            },
            {
              "value": "አምዴ ወርቅ",
              "title": "አምዴ ወርቅ"
            },
            {
              "value": "ላሊበላ",
              "title": "ላሊበላ"
            },
            {
              "value": "ኮቦ",
              "title": "ኮቦ"
            },
            {
              "value": "ሰኮታ",
              "title": "ሰኮታ"
            },
            {
              "value": "ኑባክ",
              "title": "ኑባክ"
            },
            {
              "value": "አየና ቡጉና",
              "title": "አየና ቡጉና"
            },
            {
              "value": "ዘቃላ",
              "title": "ዘቃላ"
            },
            {
              "value": "ሮቢት",
              "title": "ሮቢት"
            },
            {
              "value": "ዎልዲያ CSC2",
              "title": "ዎልዲያ CSC2"
            },
            {
              "value": "ሙጃ",
              "title": "ሙጃ"
            }
          ]
        }
      
      
      ]},// This is where Amhara Ends. Comma here
      {
        value: 'ደቡብ ብሄርስቦችና ህዝቦች ክልል',
        title: 'ደቡብ ብሄርስቦችና ህዝቦች ክልል',
        children: [
          {
            "value": "ሆሳዕና ወረዳ",
            "title": "ሆሳዕና ወረዳ",
            "children": [
              {
                "value": "ሆሽና",
                "title": "ሆሽና"
              },
              {
                "value": "ወልክቴ",
                "title": "ወልክቴ"
              },
              {
                "value": "ቡታጅራ",
                "title": "ቡታጅራ"
              },
              {
                "value": "ወራቤ",
                "title": "ወራቤ"
              },
              {
                "value": "ቡዌ",
                "title": "ቡዌ"
              },
              {
                "value": "እንዲቤር",
                "title": "እንዲቤር"
              },
              {
                "value": "ግንጪር",
                "title": "ግንጪር"
              },
              {
                "value": "አገና",
                "title": "አገና"
              },
              {
                "value": "ዳርጌ",
                "title": "ዳርጌ"
              },
              {
                "value": "ዳዖጫ",
                "title": "ዳዖጫ"
              },
              {
                "value": "ቶራ",
                "title": "ቶራ"
              },
              {
                "value": "ዱራሜ",
                "title": "ዱራሜ"
              },
              {
                "value": "ግንቢጫ",
                "title": "ግንቢጫ"
              },
              {
                "value": "ሙዱላ",
                "title": "ሙዱላ"
              },
              {
                "value": "ዶዮገና",
                "title": "ዶዮገና"
              },
              {
                "value": "አንጋጫ",
                "title": "አንጋጫ"
              },
              {
                "value": "ለራ",
                "title": "ለራ"
              },
              {
                "value": "ሞርሲቶ",
                "title": "ሞርሲቶ"
              },
              {
                "value": "ሽንሺቾ",
                "title": "ሽንሺቾ"
              },
              {
                "value": "ሆሳዕና ቁጥር 2",
                "title": "ሆሳዕና ቁጥር 2"
              },
              {
                "value": "ሃዋሪያት",
                "title": "ሃዋሪያት"
              },
              {
                "value": "ጎምቦራ",
                "title": "ጎምቦራ"
              },
              {
                "value": "አረቂት",
                "title": "አረቂት"
              },
              {
                "value": "ፎንቄ",
                "title": "ፎንቄ"
              },
              {
                "value": "ዲንቁላ",
                "title": "ዲንቁላ"
              },
              {
                "value": "ኮሽ",
                "title": "ኮሽ"
              }
            ]
          },
          {
            "value": "ወላይታ ወረዳ",
            "title": "ወላይታ ወረዳ",
            "children": [
              {
                "value": "ወላይታ ሶዶ ቁጥር 1",
                "title": "ወላይታ ሶዶ ቁጥር 1"
              },
              {
                "value": "ወላይታ ሶዶ ቁጥር 2",
                "title": "ወላይታ ሶዶ ቁጥር 2"
              },
              {
                "value": "ቤሌ (ጉኒኒ)",
                "title": "ቤሌ (ጉኒኒ)"
              },
              {
                "value": "ቦዲቲ",
                "title": "ቦዲቲ"
              },
              {
                "value": "ገሱፓን",
                "title": "ገሱፓን"
              },
              {
                "value": "ሃዴሮ",
                "title": "ሃዴሮ"
              },
              {
                "value": "ሁምቦ",
                "title": "ሁምቦ"
              },
              {
                "value": "ሾኔ",
                "title": "ሾኔ"
              },
              {
                "value": "አላባ",
                "title": "አላባ"
              },
              {
                "value": "ቡሌ",
                "title": "ቡሌ"
              },
              {
                "value": "ወናጎ",
                "title": "ወናጎ"
              },
              {
                "value": "ይርጋጫፌ",
                "title": "ይርጋጫፌ"
              },
              {
                "value": "ዲላ",
                "title": "ዲላ"
              },
              {
                "value": "ቸሌሌክቱ",
                "title": "ቸሌሌክቱ"
              },
              {
                "value": "አማሮቼሌ",
                "title": "አማሮቼሌ"
              },
              {
                "value": "ቦረዳ",
                "title": "ቦረዳ"
              }
            ]
          },
          {
            "value": "አርባ ምንጭ ወረዳ",
            "title": "አርባ ምንጭ ወረዳ",
            "children": [
              {
                "value": "አርባ ምንጭ ቁጥር 1",
                "title": "አርባ ምንጭ ቁጥር 1"
              },
              {
                "value": "አርባ ምንጭ ቁጥር 2",
                "title": "አርባ ምንጭ ቁጥር 2"
              },
              {
                "value": "ሳወላ",
                "title": "ሳወላ"
              },
              {
                "value": "ቸንጫ",
                "title": "ቸንጫ"
              },
              {
                "value": "ግሬሴ / ቦንከ",
                "title": "ግሬሴ / ቦንከ"
              },
              {
                "value": "ባስኮቶ",
                "title": "ባስኮቶ"
              },
              {
                "value": "ሰገን",
                "title": "ሰገን"
              },
              {
                "value": "ጂንካ",
                "title": "ጂንካ"
              },
              {
                "value": "ዲሜካ",
                "title": "ዲሜካ"
              },
              {
                "value": "ሚራባባይ",
                "title": "ሚራባባይ"
              },
              {
                "value": "ቤቶ",
                "title": "ቤቶ"
              },
              {
                "value": "ጊዶሌ / ዴራሸ",
                "title": "ጊዶሌ / ዴራሸ"
              },
              {
                "value": "ሴላምበር",
                "title": "ሴላምበር"
              },
              {
                "value": "ኮንሶ",
                "title": "ኮንሶ"
              },
              {
                "value": "ቡርጂ",
                "title": "ቡርጂ"
              },
              {
                "value": "ገዴብ",
                "title": "ገዴብ"
              },
              {
                "value": "ከንባ",
                "title": "ከንባ"
              },
              {
                "value": "ዲታ",
                "title": "ዲታ"
              }
            ]
          },
          {
            "value": "ሃዋሳ ወረዳ",
            "title": "ሃዋሳ ወረዳ",
            "children": [
              {
                "value": "ሃዋሳ ቁጥር 1",
                "title": "ሃዋሳ ቁጥር 1"
              },
              {
                "value": "ሃዋሳ ቁጥር 2",
                "title": "ሃዋሳ ቁጥር 2"
              },
              {
                "value": "ሃዋሳ ቁጥር 3",
                "title": "ሃዋሳ ቁጥር 3"
              },
              {
                "value": "ሃዋሳ ቁጥር 4",
                "title": "ሃዋሳ ቁጥር 4"
              },
              {
                "value": "አሌታ ወንዶ",
                "title": "አሌታ ወንዶ"
              },
              {
                "value": "ዳዬ",
                "title": "ዳዬ"
              },
              {
                "value": "ህ/ሴላሜ",
                "title": "ህ/ሴላሜ"
              },
              {
                "value": "ይርጋሌምር",
                "title": "ይርጋሌምር"
              },
              {
                "value": "ይርባ",
                "title": "ይርባ"
              },
              {
                "value": "ሼቤዲኖሊኩ",
                "title": "ሼቤዲኖሊኩ" 
              },
              {
                "value": "አርቤዋና",
                "title": "አርቤዋና"
              },
              {
                "value": "ጨኮ",
                "title": "ጨኮ"
              },
              {
                "value": "ግርጃ",
                "title": "ግርጃ"
              },
              {
                "value": "ከባዶ",
                "title": "ከባዶ"
              },
              {
                "value": "ወንዶገኔት",
                "title": "ወንዶገኔት"
              }
            ]
          }
        
        
        ]},//SNNPR Ends here. Comma here
  
        {
          value: 'ሶማሊያ ክልል',
          title: 'ሶማሊያ ክልል',
          children: [
            {
              "value": "ጎዴ ወረዳ",
              "title": "ጎዴ ወረዳ",
              "children": [
                {
                  "value": "ጎዴ ቢአር",
                  "title": "ጎዴ ቢአር"
                },
                {
                  "value": "ዶሎዶ",
                  "title": "ዶሎዶ"
                },
                {
                  "value": "ሃርገሌ",
                  "title": "ሃርገሌ"
                },
                {
                  "value": "ሺላቦ",
                  "title": "ሺላቦ"
                },
                {
                  "value": "ጋሻሞ",
                  "title": "ጋሻሞ"
                },
                {
                  "value": "ቦሄ",
                  "title": "ቦሄ"
                },
                {
                  "value": "ዋርደር",
                  "title": "ዋርደር"
                },
                {
                  "value": "ከብሪድህር",
                  "title": "ከብሪድህር"
                },
                {
                  "value": "ደኔን",
                  "title": "ደኔን"
                },
                {
                  "value": "ኢሚ",
                  "title": "ኢሚ"
                },
                {
                  "value": "ፍሌቱ",
                  "title": "ፍሌቱ"
                },
                {
                  "value": "ከላፎ",
                  "title": "ከላፎ"
                },
                {
                  "value": "ፈርፈር",
                  "title": "ፈርፈር"
                }
              ]
            },
            {
              "value": "ጅግጅጋ ወረዳ",
              "title": "ጅግጅጋ ወረዳ",
              "children": [
                {
                  "value": "አዬሻ",
                  "title": "አዬሻ"
                },
                {
                  "value": "ኤረር",
                  "title": "ኤረር"
                },
                {
                  "value": "ኩሌን",
                  "title": "ኩሌን"
                },
                {
                  "value": "አዋሬ",
                  "title": "አዋሬ"
                },
                {
                  "value": "ሼኮሺ",
                  "title": "ሼኮሺ"
                },
                {
                  "value": "ፍሌ",
                  "title": "ፍሌ"
                },
                {
                  "value": "አውበሬ",
                  "title": "አውበሬ"
                },
                {
                  "value": "ከብሪቤያሂ",
                  "title": "ከብሪቤያሂ"
                },
                {
                  "value": "ደገሃቡር",
                  "title": "ደገሃቡር"
                },
                {
                  "value": "ጅግጅጋ ቁጥር 1",
                  "title": "ጅግጅጋ ቁጥር 1"
                },
                {
                  "value": "ጅግጅጋ ቁጥር 2",
                  "title": "ጅግጅጋ ቁጥር 2"
                },
                {
                  "value": "ፋፈን",
                  "title": "ፋፈን"
                },
                {
                  "value": "ቶጎውኻሌ",
                  "title": "ቶጎውኻሌ"
                }
              ]
            }
          ]},//Somalia Ends Here. add comma here
          {
            value: 'ትግራይ ክልል',
            title: 'ትግራይ ክልል',
            children: [
              {
                "value": "መቐለ ወረዳ",
                "title": "መቐለ ወረዳ",
                "children": [
                  {
                    "value": "ውቅሮ",
                    "title": "ውቅሮ"
                  },
                  {
                    "value": "ደዋን",
                    "title": "ደዋን"
                  },
                  {
                    "value": "ትምጉዃ/ዋጃ",
                    "title": "ትምጉዃ/ዋጃ"
                  },
                  {
                    "value": "ቢዘት",
                    "title": "ቢዘት"
                  },
                  {
                    "value": "ፍይረዋይኒ",
                    "title": "ፍይረዋይኒ"
                  },
                  {
                    "value": "የጭላ",
                    "title": "የጭላ"
                  },
                  {
                    "value": "ሓዋዘን",
                    "title": "ሓዋዘን"
                  },
                  {
                    "value": "አዲሽ ሄውአትስቢ",
                    "title": "አዲሽ ሄውአትስቢ"
                  },
                  {
                    "value": "ሓገረ ሰላም",
                    "title": "ሓገረ ሰላም"
                  },
                  {
                    "value": "ዕድጋ ሓሙስ",
                    "title": "ዕድጋ ሓሙስ"
                  },
                  {
                    "value": "መሓኒ",
                    "title": "መሓኒ"
                  },
                  {
                    "value": "ሳምረ",
                    "title": "ሳምረ"
                  },
                  {
                    "value": "ዛላንበሳ",
                    "title": "ዛላንበሳ"
                  },
                  {
                    "value": "ቆረም",
                    "title": "ቆረም"
                  },
                  {
                    "value": "አዲጉደም",
                    "title": "አዲጉደም"
                  },
                  {
                    "value": "አሲ ዓዲ",
                    "title": "አሲ ዓዲ"
                  },
                  {
                    "value": "መይጨው",
                    "title": "መይጨው"
                  },
                  {
                    "value": "ኳይሓ",
                    "title": "ኳይሓ"
                  },
                  {
                    "value": "አላማታ",
                    "title": "አላማታ"
                  },
                  {
                    "value": "መቐለ ዞን ከትለ 2",
                    "title": "መቐለ ዞን ከትለ 2"
                  },
                  {
                    "value": "ዓድእራት",
                    "title": "ዓድእራት"
                  },
                  {
                    "value": "መቐለ ዞን ከትለ 1",
                    "title": "መቐለ ዞን ከትለ 1"
                  },
                  {
                    "value": "መቐለ ዞን ከትለ 4(ኢንዱስትሪ ፓርክ)",
                    "title": "መቐለ ዞን ከትለ 4(ኢንዱስትሪ ፓርክ)"
                  },
                  {
                    "value": "አጉላይ",
                    "title": "አጉላይ"
                  }
                ]
              },
              {
                "value": "ሸር ወረዳ",
                "title": "ሸር ወረዳ",
                "children": [
                  {
                    "value": "ማይሃንሰይ",
                    "title": "ማይሃንሰይ"
                  },
                  {
                    "value": "ሺራሮ",
                    "title": "ሺራሮ"
                  },
                  {
                    "value": "ሑመራ",
                    "title": "ሑመራ"
                  },
                  {
                    "value": "ዳንሻ",
                    "title": "ዳንሻ"
                  },
                  {
                    "value": "ሰመማ",
                    "title": "ሰመማ"
                  },
                  {
                    "value": "አዲ ዳዕሮ",
                    "title": "አዲ ዳዕሮ"
                  },
                  {
                    "value": "ነበለት",
                    "title": "ነበለት"
                  },
                  {
                    "value": "ማይ ስተብሪ",
                    "title": "ማይ ስተብሪ"
                  },
                  {
                    "value": "ሰለክለካ",
                    "title": "ሰለክለካ"
                  },
                  {
                    "value": "እንዳባ ጉና",
                    "title": "እንዳባ ጉና"
                  },
                  {
                    "value": "ውቅሮ ማራይ",
                    "title": "ውቅሮ ማራይ"
                  },
                  {
                    "value": "ራማ",
                    "title": "ራማ"
                  },
                  {
                    "value": "አዲጋ አርቢ",
                    "title": "አዲጋ አርቢ"
                  },
                  {
                    "value": "ባየከር ኢንዱስትሪ ፓርክ",
                    "title": "ባየከር ኢንዱስትሪ ፓርክ"
                  },
                  {
                    "value": "እንጥቾ",
                    "title": "እንጥቾ"
                  },
                  {
                    "value": "አድዋ",
                    "title": "አድዋ"
                  },
                  {
                    "value": "ሺረ",
                    "title": "ሺረ"
                  },
                  {
                    "value": "አክሱም",
                    "title": "አክሱም"
                  },
                  {
                    "value": "አዲ ረመትስ",
                    "title": "አዲ ረመትስ"
                  },
                  {
                    "value": "ማይ ጋባ",
                    "title": "ማይ ጋባ"
                  },
                  {
                    "value": "አዲ ሓገራይ",
                    "title": "አዲ ሓገራይ"
                  }
                ]
              },
              
            
            ]},// Tigray ends Here
            { value: 'እፋር ክልል',
              title: 'እፋር ክልል',
              children: [
                {
                  "value": "ሰመራ ሲ.ኤስ.ሲ",
                  "title": "ሰመራ ሲ.ኤስ.ሲ"
                },
                {
                  "value": "መልካ ውረር",
                  "title": "መልካ ውረር"
                },
                {
                  "value": "አዋሽ 7 ኪሎ",
                  "title": "አዋሽ 7 ኪሎ"
                },
                {
                  "value": "ተላላክ",
                  "title": "ተላላክ"
                },
                {
                  "value": "ጌዋነ",
                  "title": "ጌዋነ"
                },
                {
                  "value": "ደቼቶ",
                  "title": "ደቼቶ"
                },
                {
                  "value": "ሎጊያ",
                  "title": "ሎጊያ"
                },
                {
                  "value": "አሳይታ",
                  "title": "አሳይታ"
                },
                {
                  "value": "ዱብቲ",
                  "title": "ዱብቲ"
                },
                {
                  "value": "ማይላ",
                  "title": "ማይላ" 
                },
                {
                  "value": "አፍደራ",
                  "title": "አፍደራ"
                },
                {
                  "value": "እልውሀ",
                  "title": "እልውሀ"
                },
                {
                  "value": "ሺከት / አባላ",
                  "title": "ሺከት / አባላ"
                },
                {
                  "value": "ብርሃለ",
                  "title": "ብርሃለ"
                },
                {
                  "value": "ችፍራ",
                  "title": "ችፍራ"
                },
                {
                  "value": "ሱኑታ",
                  "title": "ሱኑታ"
                },
                {
                  "value": "ከለዋን",
                  "title": "ከለዋን"
                },
                {
                  "value": "ኩነባ ሲ.ኤስ.ሲ",
                  "title": "ኩነባ ሲ.ኤስ.ሲ"
                }
              
              
              ]}, //Afar Ends here, add Comma
              {
    
                value: 'ድሬ ደዋ',
                title: 'ድሬ ደዋ',
                children: [
                  
                    {
                      "value": "ድሬ ዳዋ ቁጥር 1",
                      "title": "ድሬ ዳዋ ቁጥር 1"
                    },
                    {
                      "value": "ድሬ ዳዋ ቁጥር 2",
                      "title": "ድሬ ዳዋ ቁጥር 2"
                    },
                    {
                      "value": "ድሬ መልካ",
                      "title": "ድሬ መልካ"
                    },
                    {
                      "value": "ገነት መናፈሻ",
                      "title": "ገነት መናፈሻ"
                    },
                    {
                      "value": "ድሬ ዳዋ ቁጥር 5 (ኢንዱስትሪ ፓርክ)",
                      "title": "ድሬ ዳዋ ቁጥር 5 (ኢንዱስትሪ ፓርክ)"
                    }
                    
                  
                ]},//Diredawa Ends Here,
                {
                  value: 'ጋምቤላ ክልል',
                  title: 'ጋምቤላ ክልል',
                  children: [
                    {
                      "value": "ጋምቤላ",
                      "title": "ጋምቤላ"
                    },
                    {
                      "value": "ኢታንግ",
                      "title": "ኢታንግ"
                    },
                    {
                      "value": "አቦቦ",
                      "title": "አቦቦ"
                    },
                    {
                      "value": "ፉግኒዶ",
                      "title": "ፉግኒዶ"
                    },
                    {
                      "value": "ዲማ",
                      "title": "ዲማ"
                    },
                    {
                      "value": "ባሮ ምዶ",
                      "title": "ባሮ ምዶ"
                    },
                    {
                      "value": "ላሬ",
                      "title": "ላሬ"
                    },
                    {
                      "value": "ኩሚ",
                      "title": "ኩሚ"
                    },
                    {
                      "value": "መቲ",
                      "title": "መቲ"
                    }
                  ]},// Gambella Ends HEre
                  {
                    "value": "ሐረር ክልል",
                    "title": "ሐረር ክልል",
                    "children": [
                      {
                        "value": "ሐረር ቁጥር 1",
                        "title": "ሐረር ቁጥር 1"
                      },
                      {
                        "value": "ሐረር ቁጥር 2",
                        "title": "ሐረር ቁጥር 2"
                      },
                      {
                        "value": "ደሬ ተያራ",
                        "title": "ደሬ ተያራ"
                      },
                      {
                        "value": "ሐረር ወልድያ",
                        "title": "ሐረር ወልድያ"
                      },
                      {
                        "value": "ሶፊ",
                        "title": "ሶፊ"
                      }
                    ]
                  },
                  {
                    "value": "ደቡብ ምዕራብ ክልል",
                    "title": "ደቡብ ምዕራብ ክልል",
                    "children": [
                      {
                        "value": "ገሳ",
                        "title": "ገሳ"
                      },
                      {
                        "value": "ተርቻ",
                        "title": "ተርቻ"
                      },
                      {
                        "value": "ቶቻ",
                        "title": "ቶቻ"
                      },
                      {
                        "value": "ቦንጋ",
                        "title": "ቦንጋ"
                      },
                      {
                        "value": "ማሻ",
                        "title": "ማሻ"
                      },
                      {
                        "value": "ምዛን",
                        "title": "ምዛን"
                      },
                      {
                        "value": "ቴፒ",
                        "title": "ቴፒ"
                      },
                      {
                        "value": "ዳካ",
                        "title": "ዳካ"
                      },
                      {
                        "value": "ኦዳ",
                        "title": "ኦዳ"
                      },
                      {
                        "value": "ቼና",
                        "title": "ቼና"
                      },
                      {
                        "value": "ብፉት",
                        "title": "ብፉት"
                      },
                      {
                        "value": "ደብረ ወርቅ",
                        "title": "ደብረ ወርቅ"
                      },
                      {
                        "value": "ሸበንች",
                        "title": "ሸበንች"
                      },
                      {
                        "value": "አሜያ-ኮንታ",
                        "title": "አሜያ-ኮንታ"
                      },
                      {
                        "value": "ችሪ",
                        "title": "ችሪ"
                      },
                      {
                        "value": "አድዮካካ",
                        "title": "አድዮካካ"
                      },
                      {
                        "value": "ግምቦ",
                        "title": "ግምቦ"
                      },
                      {
                        "value": "ጄሙ",
                        "title": "ጄሙ"
                      }
                    ]
                  },
                  {
                     value: 'ቤንሻንጉል ጉሙዝ ክልል',
                     title: "ቤንሻንጉል ጉሙዝ ክልል",
                     children: [
                      {
                        value: 'አሶሳ ዞን',
                        title: 'አሶሳ ዞን',
                        children: [
                          {
                            value: 'አሶሳ',
                            title: 'አሶሳ'
                          },
                          {
                            value: 'መኔጌ',
                            title: 'መኔጌ'
                          },
                          {
                            value: 'ባምባሲ',
                            title: 'ባምባሲ'
                          },
                          {
                            value: 'ኩሚሩክ',
                            title: 'ኩሚሩክ'
                          },
                          {
                            value: 'ፓዊ',
                            title: 'ፓዊ'
                          },
                          {
                            value: 'ሶጌ',
                            title: 'ሶጌ'
                          },
                          {
                            value: 'ደብረሰይቴ',
                            title: 'ደብረሰይቴ'
                          },
                          {
                            value: 'ማንኩሽ',
                            title: 'ማንኩሽ'
                          },
                          {
                            value: 'ቡለን',
                            title: 'ቡለን'
                          },
                          {
                            value: 'ዎዳ ቢለንዲጌሉ',
                            title: 'ዎዳ ቢለንዲጌሉ'
                          },
                          {
                            value: 'ቶንጎ',
                            title: 'ቶንጎ'
                          },
                          {
                            value: 'ካማሺ',
                            title: 'ካማሺ'
                          }
                        ]
                      },
                     ]
                  },
                  {
                    value: 'ዋና መስሪያ ቤት',
                    title: 'ዋና መስሪያ ቤት',
                  },
                  {
                    value: 'PMO',
                    title: 'PMO',
                  }
  
      ]
     //The End 
  
  
  
  
  