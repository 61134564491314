import { useCallback, useContext, useEffect, useState } from "react";
import "../styles.css";
import eeu_logo from '../assets/eeu_white_logo.png';
import { gsapLandingAnimation } from "../animations/gsapAnimation";
import { animateWordChange } from "../animations/animateWords";
import { LanguageSwitch } from "./styles";
import { withTranslation } from "react-i18next";
import i18n from "../translation";

const LeftSide = ({t}) => {

useEffect(()=> {
  var mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
//   if(!mediaQuery.matches) {
//       gsapLandingAnimation();
//   }
  animateWordChange();
}, [i18n.language])

const handleChange = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('i18nextLng', language);
  };

    return (
        <div className="signUp-left signUp-column">
        <div className='logo-text'>
          <img
                  style={{ width: "50px", height: "50px" }}
                  src={eeu_logo}
                  alt="picture"
              />
       <div style={{marginLeft: 10, fontSize: 18}}>
              <div style={{
          //  fontFamily: FONTFAMILY.poppins_bold,
           fontSize: 18, color: "black",
          //  color: "#F9A34C"
           }}>
            የኢትዮጵያ  ኤሌክትሪክ አገልግሎት 
        </div>
        <div style={{marginTop:-10,
           fontSize: 20, color: "black",
          //  color: "#69BF70"
           }}>
            Ethiopian Electric Utility
        </div>
        </div>
        </div>
           <div className="leftText">
           {i18n.language == 'en'?
             <>
           <h1>
           <span>This form allows you to <br/> report Corruption
                    </span>
                    <span>in a safe and confidential manner.</span>

                    {/* <span>This form allows you to <br/> report&nbsp;
                        <span className="word" style={{color: "red"}}>Corruption</span>
                        <span className="word" style={{color: "#FFFFFF"}}>Bribery</span>
                        <span className="word">Theft</span>
                        <span className="word" style={{color: "#FFFFFF"}}>Billing fraud</span>
                        <span className="word">Harassment</span>
                    </span>
                    <span>in a safe and confidential manner.</span> */}
                </h1>
                <p>
                your contact information will be kept strictly confidential. 
                It will only be accessed by authorized personnel involved in the investigation 
                and will not be shared with anyone outside the designated team.
                </p>
                </>:
                    <div>
                        <h1>
                    <span>የሚያቀርቡት ጥቆማ ሚስጥራዊነቱን የተጠበቀ ስለሆነ ጥቆማዎትን በመተማመን እንዲያቀርቡ እናበረታታለን </span>
                </h1>
                <p>
                  የሚያቀርቡት ጥቆማ በተገቢው መንገድ አጣርቶ ትክክለኛ እርምጃ ለመውሰድ እንዲያስችል ጥቆማዎትን በትክክል ቅፁ በሚጠይቀው መልኩ እንዲሞሉ እና ደጋፊ መረጃዎትን እንዲያቀርቡ በማክበር እንጠይቃለን።
                </p>
                
                </div>}
                <div style={{ display: 'flex', justifyContent: 'flex-end', zIndex: 900}}>
                {i18n.language == 'es' ? <LanguageSwitch onClick={() => handleChange("en")}>
                  <div>English</div>
                </LanguageSwitch>:
                <LanguageSwitch onClick={() => handleChange("es")}>
                  <div>አማርኛ</div>
                </LanguageSwitch>}
                </div>
            </div>
                
            </div>
    )
}

export default withTranslation()(LeftSide);