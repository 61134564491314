import { React, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import SignupForm from './signupForm';
import { withTranslation } from 'react-i18next';
import i18n from '../translation';


const BoxContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  width: ${({ theme }) => theme.width || '480px'};
  min-height: 550px;
  display: flex;
  flex-direction: column;
  border-radius: 19px;
  background-color: #fff;
  box-shadow: 0 0 2px rgba(15, 15, 15, 0.28);
  position: relative;
overflow-x: hidden;
     overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer */

  @media (max-width: 700px) {
    width: 380px;
  }
`;

const TopContainer = styled.div`
  width: 100%;
  // height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 1.8em;
  padding-bottom: 5em;

  @media (max-width: 1000px) {
     padding-bottom: 3em;
  }
  @media (max-width: 700px) {
     padding-bottom: 0em;
  }
`;

const BackDrop = styled(motion.div)`
  position: absolute;
  width: 200%;
  height: 550px;
  display: flex;
  flex-direction: column;
  border-radius: 50%;
  top: -370px;
  left: -300px;
  transform: rotate(0deg);
  background: linear-gradient(
    58deg, rgba(243,172,18,1) 20%, rgba(241,196,15,1) 100%
  );

   @media (max-width: 700px) {
    position: absolute;
  width: 160%;
  height: 550px;
  display: flex;
  flex-direction: column;
  border-radius: 50%;
  top: -380px;
  left: -160px;
  transform: rotate(60deg);
  background: linear-gradient(
    58deg, rgba(243,172,18,1) 20%, rgba(241,196,15,1) 100%
  );
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
`;

const HeaderText = styled.div`
  font-size: 30px;
  font-weight: 600;
  line-height: 1.24;
  color: #fff;
  z-index: 10;
`;

const SmallText = styled.div`
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  margin-top: 7px;
  z-index: 10;
`;

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
`;

export const LanguageSwitch = styled("div")`
  display: none;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  color: #76b852;
  z-index: 1000;

  &:hover,
  &:active,
  &:focus {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
    @media (max-width: 1000px) {
      display: flex;
      position: absolute;
       margin-top: 20px;
    }

    @media (max-width: 1000px) {
      display: flex;
    }
`;


// export default function AccountBox(props) {
const AccountBox = ({t}) => {

  const handleChange = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('i18nextLng', language);
  };

    return (
        <BoxContainer>
            <TopContainer>
            <BackDrop 
        // initial={false}
        // animate={"collapsed"}
        // variants={backdropVariants}
        // transition={expandingTransition}
      />
      <HeaderContainer>
        {/* <HeaderText>Create</HeaderText> */}
        <HeaderText>{t("Ethics and Anti-Corruption Directorate Public Feed Back System")}</HeaderText>
        {/* <SmallText>Ethics and Anti-Corruption Directorate Public Feed Back System</SmallText> */}
        <div style={{ display: 'flex', justifyContent: 'flex-end', zIndex: 900}}>
                {i18n.language == 'es' ? <LanguageSwitch onClick={() => handleChange("en")}>
                  <div>English</div>
                </LanguageSwitch>:
                <LanguageSwitch  onClick={() => handleChange("es")}>
                  <div>አማርኛ</div>
                </LanguageSwitch>}
                </div>
      </HeaderContainer>

            </TopContainer>

            <InnerContainer>
              <SignupForm/>
    </InnerContainer>

        </BoxContainer>
  );
}

export default withTranslation()(AccountBox);